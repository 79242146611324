<template>
    <a-modal :visible="showModal" :width="960" :destroyOnClose="true" @cancel="handleCancel">
        <template v-slot:title>
            <span style="font-size:13px">查看日志</span>
        </template>
        <template v-slot:closeIcon>
            <a-icon type="close" style="color: #3C4A54;font-size: 12px;margin-top:10px;margin-right:10px;float:right;" />
        </template>

        <template v-slot:footer>
            <div>
                <a-button @click="handleCancel">关闭</a-button>
            </div>
        </template>
        <div class="main">
            <a-spin :spinning="loading">
<!--              <div v-html="renderLog(detailData.logContext)" style="text-align: center;"/>-->
              <div v-html="renderLog(detailData.logContext)" style=""/>
            </a-spin>
        </div>
    </a-modal>
</template>

<script>
    import { showProgressLog } from "@/api/ai/train.js";
    import {  message, Modal } from 'ant-design-vue';
    import { baseUrl } from '../../utils/request';

    export default {
        name: "ViewTrain",
        components: { },
        props: {
            showModal: {
                type: Boolean,
                default: false
            },
            reportData: {
                type:Object,
                default:null
            },
        },

        data() {
            return {
                loading: false,
                detailData: {},
            }
        },
        created() {
            this.showProgressLog();
        },
        watch: {
            showModal(value) {
                if (this.showModal) {
                    this.showProgressLog();
                }
            }
        },
        methods:{
            // 获取详情数据
            showProgressLog(){
                if(!this.reportData.id) {
                    return;
                }
                this.loading = true;
                showProgressLog(this.reportData.id).then(res => {
                    this.loading = false;
                    if(res.success && res.data) {
                        this.detailData = res.data || {};
                    }
                });
            },
            handleCancel(){
                this.$emit("close");
            },
            renderLog(log) {
              return log ? log.replace(/\\n/g, '<br/>') : log;
            }
        }
    }
</script>

<style scoped>
    .dialogTitle{
        background: lightgray;
        font-size:12px;
    }
    .main {
        background: white;
    }

    .subtitle {
        font-weight: bolder;
        font-size: 24px;
        text-align: center;
        color:black;
    }

    .head {
        height: 61px;
        background: url("../../assets/banner.png") no-repeat;
        background-size:224px 61px
    }

    /*/deep/ .ant-modal-content{*/
    /*  padding: 0px;*/
    /*  margin:0px;*/
    /*  border:1px solid blue;*/
    /*}*/
    /deep/ .ant-modal-header {
  /* background: lightgray; */
  height: 31px;
  padding-top: 3px;
  padding-left: 6px;
  border-bottom: 1px solid #EEEEEE;
}
    /deep/ .ant-modal-body {
        padding: 0px;
    }
    /deep/ .ant-row{
        margin:0px;
        padding:0px;
    }

</style>
