<template>
    <a-modal :visible="showModal" :width="1000" :destroyOnClose="true" @cancel="handleCancel">
        <template v-slot:title>
            <span style="font-size:13px">{{ getHandleType() }}调参</span>
        </template>
        <template v-slot:closeIcon>
            <a-tooltip>
                <template slot="title">
                    <div>待检测曲线的最短像素长度，值越大检测将剔除越多的短曲线；</div>
                    <div>从最小值到最大值，按照固定步长取得参数；</div>
                    <div>待检测曲线的对比度值，取值0~1，值越大将剔除越多不明显的曲线；</div>
                    <div>从最小值到最大值，按照固定步长取得参数；</div>
                </template>
                <a-icon 
                    type="info-circle" 
                    style="color: rgba(0,0,0,.45); position: relative; top: -12px; right: 3px" 
                    v-on:click.stop="() => {}"
                />
            </a-tooltip>
            <a-icon type="close" style="color: #3C4A54;font-size: 12px;margin-top:10px;margin-right:10px;float:right;" />
        </template>

        <template v-slot:footer>
            <div>
                <a-button type="primary" style="" @click="saveReport" v-if="!isView">保存</a-button>
                <a-button style="" @click="handleCancel">关闭</a-button>
            </div>
        </template>
        <div class="main">
            <a-spin :spinning="loading">
            <a-form-model ref="form" :model="form" :rules="rules" :labelCol="{span:12}" :wrapperCol="{span:12}" style="margin:5px; padding-bottom: 10px;">
              <a-row>
                  <a-col :span="8">
                      <a-form-model-item label="调参名称：" prop="name">
                        <div v-if="this.isView">
                            <a-input v-model="form.name" disabled="disabled"/>
                        </div>
                        <a-input v-model="form.name" placeholder="请输入调参名称" allowClear v-else />
                      </a-form-model-item>
                  </a-col>
                  <a-col :span="8">
                      <a-form-model-item label="模型名称：" prop="modelName">
                          <div v-if="this.isView">
                            <a-select v-model="form.modelName" disabled="disabled">
                            <a-select-option v-for="item in modelList" :key="item.name" :value="item.name">{{ item.name }}</a-select-option>
                        </a-select>
                            </div>
                          <a-select v-model="form.modelName" placeholder="请选择模型名称" allowClear show-search v-else>
                            <a-select-option v-for="item in modelList" :key="item.name" :value="item.name">{{ item.name }}</a-select-option>
                        </a-select>
                      </a-form-model-item>
                  </a-col>
                  <a-col :span="8">
                      <a-form-model-item label="数据集：" prop="datasetIdArr">
                          <div v-if="this.isView">
                            <a-select v-model="form.datasetIdArr" disabled="disabled">
                            <a-select-option v-for="item in datasetList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                        </a-select>
                            <!-- {{ renderDataSetName() }} -->
                        </div>
                          <a-select v-model="form.datasetIdArr" placeholder="请选择数据集" allowClear show-search mode="multiple" v-else>
                            <a-select-option v-for="item in datasetList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                        </a-select>
                      </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="8">
                      <a-form-model-item label="曲线最短长度：最小值：" prop="parameter1">
                          <div v-if="this.isView">
                            <a-input v-model="form.parameter1" disabled="disabled">
                          </a-input></div>
                          <a-input v-model="form.parameter1" placeholder="请输入曲线最短长度：最小值" allowClear v-else>
                            <!--<a-tooltip slot="suffix">
                                <template slot="title">
                                    <div>待检测曲线的最短像素长度，值越大检测将剔除越多的短曲线；</div>
                                    <div>从最小值到最大值，按照固定步长取得参数；</div>
                                </template>
                                <a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
                            </a-tooltip>-->
                          </a-input>
                      </a-form-model-item>
                  </a-col>
                  <a-col :span="8">
                      <a-form-model-item label="曲线最短长度：最大值：" prop="parameter2">
                          <div v-if="this.isView">
                            <a-input v-model="form.parameter2" disabled="disabled">
                          </a-input>
                          </div>
                          <a-input v-model="form.parameter2" placeholder="请输入曲线最短长度：最大值" allowClear v-else>
                            <!--<a-tooltip slot="suffix">
                                 <template slot="title">
                                    <div>待检测曲线的最短像素长度，值越大检测将剔除越多的短曲线；</div>
                                    <div>从最小值到最大值，按照固定步长取得参数；</div>
                                </template>
                                <a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
                            </a-tooltip>-->
                          </a-input>
                      </a-form-model-item>
                  </a-col>
                  <a-col :span="8">
                      <a-form-model-item label="曲线最短长度：步长：" prop="parameter3">
                          <div v-if="this.isView">
                            <a-input v-model="form.parameter3" disabled="disabled">
                          </a-input>
                           </div>
                          <a-input v-model="form.parameter3" placeholder="请输入曲线最短长度：步长" allowClear v-else>
                            <!--<a-tooltip slot="suffix" >
                                <template slot="title">
                                    <div>待检测曲线的最短像素长度，值越大检测将剔除越多的短曲线；</div>
                                    <div>从最小值到最大值，按照固定步长取得参数；</div>
                                </template>
                                <a-icon type="info-circle" style="color: rgba(0,0,0,.45); position: relative;top: -11px;right: 3px;" />
                            </a-tooltip>-->
                          </a-input>
                      </a-form-model-item>
                  </a-col>
                  </a-row>
                  <a-row>
                  <a-col :span="8">
                      <a-form-model-item label="曲线对比度：最小值：" prop="parameter4">
                          <div v-if="this.isView">
                            <a-input v-model="form.parameter4" disabled="disabled">
                          </a-input>
                         </div>
                          <a-input v-model="form.parameter4" placeholder="请输入曲线对比度：最小值" allowClear v-else>
                            <!--<a-tooltip slot="suffix">
                                <template slot="title">
                                    <div>待检测曲线的对比度值，取值0~1，值越大将剔除越多不明显的曲线；</div>
                                    <div>从最小值到最大值，按照固定步长取得参数；</div>
                                </template>
                                <a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
                            </a-tooltip>-->
                          </a-input>
                      </a-form-model-item>
                  </a-col>
                  <a-col :span="8">
                      <a-form-model-item label="曲线对比度：最大值：" prop="parameter5">
                          <div v-if="this.isView">
                            <a-input v-model="form.parameter5" disabled="disabled">
                          </a-input>
                         </div>
                          <a-input v-model="form.parameter5" placeholder="请输入曲线对比度：最大值" allowClear v-else>
                            <!--<a-tooltip slot="suffix">
                                <template slot="title">
                                    <div>待检测曲线的对比度值，取值0~1，值越大将剔除越多不明显的曲线；</div>
                                    <div>从最小值到最大值，按照固定步长取得参数；</div>
                                </template>
                                <a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
                            </a-tooltip>-->
                          </a-input>
                      </a-form-model-item>
                  </a-col>
                <a-col :span="8">
                  <a-form-model-item label="曲线对比度：步长：" prop="parameter6">
                    <div v-if="this.isView">
                        <a-input v-model="form.parameter6" disabled="disabled">
                    </a-input>
                       </div>
                    <a-input v-model="form.parameter6" placeholder="请输入曲线对比度：步长" allowClear v-else>
                        <!--<a-tooltip slot="suffix">
                            <template slot="title">
                                <div>待检测曲线的对比度值，取值0~1，值越大将剔除越多不明显的曲线；</div>
                                <div>从最小值到最大值，按照固定步长取得参数；</div>
                            </template>
                            <a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
                        </a-tooltip>-->
                    </a-input>
                  </a-form-model-item>
                </a-col>
                </a-row>
                <a-row>
                <a-col :span="8">
                  <a-form-model-item label="样品类型：" prop="type">
                    <div v-if="this.isView">
                        <a-select v-model="form.type" disabled="disabled">
                        <a-select-option key="条纹相" value="条纹相">条纹相</a-select-option>
                        <a-select-option key="三氧化二铝棒状" value="三氧化二铝棒状">三氧化二铝棒状</a-select-option>
                    </a-select>
                      </div>
                    <a-select v-model="form.type" placeholder="请选择样品类型" allowClear show-search v-else>
                        <a-select-option key="条纹相" value="条纹相">条纹相</a-select-option>
                        <a-select-option key="三氧化二铝棒状" value="三氧化二铝棒状">三氧化二铝棒状</a-select-option>
                    </a-select>
                    <!-- <a-select v-model="form.type" placeholder="请选择类型" allowClear show-search mode="multiple" v-else>
                      <a-select-option v-for="item in typeList" :key="item.name" :value="item.name">{{ item.name }}</a-select-option>
                    </a-select> -->
                  </a-form-model-item>
                </a-col>
                  <!--
                  <a-col :span="8">
                      <a-form-model-item label="参数7：" prop="parameter7">
                          <div v-if="this.isView">{{ form.parameter7 }}</div>
                          <a-input v-model="form.parameter7" placeholder="请输入参数7" allowClear v-else/>
                      </a-form-model-item>
                  </a-col>
                  <a-col :span="8">
                      <a-form-model-item label="参数8：" prop="parameter8">
                          <div v-if="this.isView">{{ form.parameter8 }}</div>
                          <a-input v-model="form.parameter8" placeholder="请输入参数8" allowClear v-else/>
                      </a-form-model-item>
                  </a-col>
                  <a-col :span="8">
                      <a-form-model-item label="参数9：" prop="parameter9">
                          <div v-if="this.isView">{{ form.parameter9 }}</div>
                          <a-input v-model="form.parameter9" placeholder="请输入参数9" allowClear v-else/>
                      </a-form-model-item>
                  </a-col>
                  <a-col :span="8">
                      <a-form-model-item label="参数10：" prop="parameter10">
                          <div v-if="this.isView">{{ form.parameter10 }}</div>
                          <a-input v-model="form.parameter10" placeholder="请输入参数10" allowClear v-else/>
                      </a-form-model-item>
                  </a-col>
                  <a-col :span="8">
                      <a-form-model-item label="参数11：" prop="parameter11">
                          <div v-if="this.isView">{{ form.parameter11 }}</div>
                          <a-input v-model="form.parameter11" placeholder="请输入参数11" allowClear v-else/>
                      </a-form-model-item>
                  </a-col>
                  <a-col :span="8">
                      <a-form-model-item label="参数12：" prop="parameter12">
                          <div v-if="this.isView">{{ form.parameter12 }}</div>
                          <a-input v-model="form.parameter12" placeholder="请输入参数12" allowClear v-else/>
                      </a-form-model-item>
                  </a-col>
                  <a-col :span="8">
                      <a-form-model-item label="参数13：" prop="parameter13">
                          <div v-if="this.isView">{{ form.parameter13 }}</div>
                          <a-input v-model="form.parameter13" placeholder="请输入参数13" allowClear v-else/>
                      </a-form-model-item>
                  </a-col>
                  <a-col :span="8">
                      <a-form-model-item label="参数14：" prop="parameter14">
                          <div v-if="this.isView">{{ form.parameter14 }}</div>
                          <a-input v-model="form.parameter14" placeholder="请输入参数14" allowClear v-else/>
                      </a-form-model-item>
                  </a-col>
                  <a-col :span="8">
                      <a-form-model-item label="参数15：" prop="parameter15">
                         <div v-if="this.isView">{{ form.parameter15 }}</div>
                          <a-input v-model="form.parameter15" placeholder="请输入参数15" allowClear v-else/>
                      </a-form-model-item>
                  </a-col>-->
              </a-row>
          </a-form-model>

        </a-spin>
        </div>
    </a-modal>
</template>

<script>
    import { optionIdAndNameList, optionModelNameList, getDetailById, addData, updateData } from "@/api/ai/train.js";
    import {  message, Modal } from 'ant-design-vue';
    import { baseUrl } from '../../utils/request';

    export default {
        name: "DataDialog",
        components: { },
        props: {
            showModal: {
                type: Boolean,
                default: false
            },
            reportData: {
                type:Object,
                default:null
            },
            handelType: {
               type: String,
               default: 'add'
            }
        },

        data() {
            return {
                loading: false,
                defaultForm: {
                    parameter1: 10,
                    parameter2: 40,
                    parameter3: 2,
                    parameter4: 0.1,
                    parameter5: 1.0,
                    parameter6: 0.1
                },
                form: {},
                modelList: [],
                datasetList: [],
                rules: {
                    name: [
                        { required: true, message: "调参名称不能为空", trigger: ["change", "blur"]}
                    ],
                    modelName: [
                        { required: true, message: "模型名称不能为空", trigger: ["change", "blur"]}
                    ],
                    datasetIdArr: [
                        { required: true, message: "数据集不能为空", trigger: ["change", "blur"]}
                    ],
                    parameter1: [
                      { required: true, message: "曲线最短长度：最小值不能为空", trigger: ["change", "blur"]}
                    ],
                    parameter2: [
                      { required: true, message: "曲线最短长度：最大值不能为空", trigger: ["change", "blur"]}
                    ],
                    parameter3: [
                      { required: true, message: "曲线最短长度：步长不能为空", trigger: ["change", "blur"]}
                    ],
                    parameter4: [
                      { required: true, message: "曲线对比度：最小值不能为空", trigger: ["change", "blur"]}
                    ],
                    parameter5: [
                      { required: true, message: "曲线对比度：最大值不能为空", trigger: ["change", "blur"]}
                    ],
                    parameter6: [
                      { required: true, message: "曲线对比度：步长不能为空", trigger: ["change", "blur"]}
                    ],
                },
                isView: false,
            }
        },
        created() {
            this.optionModelNameList();
            this.optionIdAndNameList();
        },
        watch: {
            showModal(value) {
                if (this.showModal) {
                    this.form = { ...this.defaultForm };
                    this.isView = this.handelType === 'view';
                    this.queryDataById();
                }
            }
        },
        methods:{
            // 获取模型数据
            optionModelNameList() {
                optionModelNameList().then(res => {
                    if(res.success && res.data) {
                        this.modelList = (res.data || []).filter(ele => ele.name !== '三氧化二铝孔隙率算法');;
                    }
                });
            },
            // 获取数据集数据
            optionIdAndNameList() {
                optionIdAndNameList().then(res => {
                    if(res.success && res.data) {
                        this.datasetList = res.data || [];
                    }
                });
            },
            // 获取详情数据
            queryDataById(){
                if(!this.reportData.id) {
                    return;
                }
                this.loading = true;
                getDetailById(this.reportData.id).then(res => {
                    this.loading = false;
                    if(res.success && res.data) {
                        const current = res.data || {};
                        this.form = {
                            ...current,
                            datasetIdArr: (current.parameterDatasetList || []).map(ele => ele.datasetId)
                        };
                    }
                });
            },

            saveReport(res){
                this.$refs["form"].validate(async valid => {
                    if (valid) {
                        this.loading = true;
                        const res = this.form.id ? await updateData(this.form) : await addData(this.form);
                        this.loading = false;
                        if(res.success) {
                            message.success("操作成功");
                            this.handleCancel();
                            this.$emit("onFresh");
                        }
                    }
                });
            },

            handleCancel(){
                this.$emit("close");
            },
            getHandleType() {
                if(this.handelType === 'add') {
                    return "新增";
                }
                if(this.handelType === 'update') {
                    return "编辑";
                }
                if(this.handelType === 'view') {
                    return "查看";
                }
            },
            renderDataSetName() {
                const names = (this.form.parameterDatasetList || []).map(ele => ele.datasetName) || [];
                return names.join(',');
            }
        }
    }
</script>

<style scoped>
    .dialogTitle{
        background: lightgray;
        font-size:12px;
    }
    .main {
        background: white;
    }

    .subtitle {
        font-weight: bolder;
        font-size: 24px;
        text-align: center;
        color:black;
    }

    .head {
        height: 61px;
        background: url("../../assets/banner.png") no-repeat;
        background-size:224px 61px
    }

    /*/deep/ .ant-modal-content{*/
    /*  padding: 0px;*/
    /*  margin:0px;*/
    /*  border:1px solid blue;*/
    /*}*/
    /deep/ .ant-modal-header {
  /* background: lightgray; */
  height: 31px;
  padding-top: 3px;
  padding-left: 6px;
  border-bottom: 1px solid #EEEEEE;
}
    /deep/ .ant-modal-body {
        padding: 0px;
    }
    /deep/ .ant-row{
        margin:0px;
        padding:0px;
    }

    ::v-deep .ant-select, ::v-deep .ant-input{
        width: 156px!important;
    }
</style>
