import request from '@/utils/request'

// 查询AI模型管理列表
export function listManage(query) {
  return request({
    url: '/api/ele/intelligenele/manage/list',
    method: 'get',
    params: query
  })
}

// 查询AI模型管理详细
export function getManage(id) {
  return request({
    url: '/api/ele/intelligenele/manage/' + id,
    method: 'get'
  })
}

// 新增AI模型管理
export function addManage(data) {
  return request({
    url: '/api/ele/intelligenele/manage',
    method: 'post',
    data: data
  })
}

// 新增训练AI模型管理
export function addTrainManage(data) {
  return request({
    url: '/api/ele/drill/parameter/saveModel',
    method: 'post',
    data: data
  })
}

// 修改AI模型管理
export function updateManage(data) {
  return request({
    url: '/api/ele/intelligenele/manage',
    method: 'put',
    data: data
  })
}

// 删除AI模型管理
export function delManage(id) {
  return request({
    url: '/api/ele/intelligenele/manage/' + id,
    method: 'delete'
  })
}
