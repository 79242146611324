<template>
    <a-modal :visible="showModal" :width="960" :destroyOnClose="true" @cancel="handleCancel">
        <template v-slot:title>
            <span style="font-size:13px">查看训练</span>
        </template>
        <template v-slot:closeIcon>
            <a-icon type="close" style="color: #3C4A54;font-size: 12px;margin-top:10px;margin-right:14px;float:right;" />
        </template>

        <template v-slot:footer>
            <div>
                <a-button @click="handleCancel">关闭</a-button>
            </div>
        </template>
        <div class="main">
            <a-spin :spinning="loading">
                <a-table rowKey="id" :columns="columns" :dataSource="list"
                    ref="table" :pagination="{
                    defaultCurrent: queryParams.pageNum, // 默认当前页数
                    defaultPageSize: queryParams.pageSize, // 默认当前页显示数据的大小
                    total: total, // 总数，必须先有
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSizeOptions: ['20', '50', '100'],
                    showTotal: (total, range) => `每页${queryParams.pageSize}条，共 ${total} 条`, // 显示总数
                    onShowSizeChange: onPageSizeChange,
                    // 改变每页数量时更新显示
                    onChange: onPageSizeChange,
                }" bordered>
                    <template v-slot:status="text">
                        <span v-if="text === '1'">等待中</span>
                        <span v-if="text === '2'">训练中</span>
                        <span v-if="text === '3'">训练完成</span>
                        <span v-if="text === '4'">训练失败</span>
                        <span v-if="text === '5'">队列中</span>
                    </template>
                    <template v-slot:action="record">
                        <a href="javascript:;" style="margin-right: 10px;" @click="showLog(record)">查看日志</a>
                        <a href="javascript:;" style="margin-right: 10px;" @click="showResult(record)" v-if="record.status === '3'">查看结果</a>
                        <a href="javascript:;" @click="openModelDialog(record)">保存算法模型</a>
                    </template>
                </a-table>
            </a-spin>
            <!-- 保存模型 -->
            <save-model :show-modal="modelOpen" :report-data="detailData" @onFresh="querylist" @close="closeModelDialog"/>
        </div>
    </a-modal>
</template>

<script>
    import { progresslist } from "@/api/ai/train.js";
    import {  message, Modal } from 'ant-design-vue';
    import { baseUrl } from '../../utils/request';
    import SaveModel from "./saveModel.vue";

    export default {
        name: "ViewTrain",
        components: { SaveModel },
        props: {
            showModal: {
                type: Boolean,
                default: false
            },
            reportData: {
                type:Object,
                default:null
            },
        },

        data() {
            return {
                loading: false,
                list: [],
                // 查询参数
                queryParams: {
                    pageNum: 1,
                    pageSize: 10,
                },
                // 总条数
                total: 0,
                columns: [
                    {
                        title: '调参名称',
                        dataIndex: 'parameterName',
                        align: "center"
                    },
                    {
                        title: '状态',
                        dataIndex: 'status',
                        align: "center",
                        scopedSlots: {customRender: 'status'}
                    },
                    {
                        title: '训练时间',
                        dataIndex: 'createTime',
                        align: "center"
                    },
                    {
                        title: '操作',
                        key: 'operation',
                        width: '240px',
                        align: "center",
                        className: 'operation-cls',
                        scopedSlots: {customRender: 'action'}
                    }
                ],
                modelOpen: false,
                detailData: {},
            }
        },
        created() {
            this.querylist();
        },
        watch: {
            showModal(value) {
                if (this.showModal) {
                    this.querylist();
                }
            }
        },
        methods:{
            // 获取详情数据
            querylist(){
                if(!this.reportData.id) {
                    return;
                }
                this.loading = true;
                progresslist({parameterId: this.reportData.id}).then(res => {
                    this.loading = false;
                    if(res.success && res.rows) {
                        this.list = res.rows || [];
                        this.total = res.total;
                    }
                });
            },
            onPageSizeChange(current, pageSize){
                this.queryParams = {
                    ...this.queryParams,
                    pageNum: current,
                    pageSize,
                };
                this.querylist();
            },
            handleCancel(){
                this.$emit("close");
            },
            // 查看日志
            showLog(row) {
                this.$emit("showLog", row);
            },
            // 查看结果
            showResult(row) {
                this.$emit("showResult", row);
            },
            // 打开模型弹框
            openModelDialog(row) {
                this.modelOpen = true;
                this.detailData = {
                    ...this.reportData,
                    drillProgressId: row.id,
                };
            },
            // 关闭模型弹框
            closeModelDialog() {
                this.modelOpen = false;
            },
        }
    }
</script>

<style scoped>
    .dialogTitle{
        background: lightgray;
        font-size:12px;
    }
    .main {
        background: white;
    }

    .subtitle {
        font-weight: bolder;
        font-size: 24px;
        text-align: center;
        color:black;
    }

    .head {
        height: 61px;
        background: url("../../assets/banner.png") no-repeat;
        background-size:224px 61px
    }

    /*/deep/ .ant-modal-content{*/
    /*  padding: 0px;*/
    /*  margin:0px;*/
    /*  border:1px solid blue;*/
    /*}*/
    /deep/ .ant-modal-header {
  /* background: lightgray; */
  height: 31px;
  padding-top: 3px;
  padding-left: 6px;
  border-bottom: 1px solid #EEEEEE;
}
    /deep/ .ant-modal-body {
        padding: 0px;
    }
    /deep/ .ant-row{
        margin:0px;
        padding:0px;
    }
    /deep/ .ant-table-pagination{
        position: relative;
        margin-top: 22px;
    }
</style>
