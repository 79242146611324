import request from '@/utils/request'

// 调参名称下拉框
export function optionNameList(query) {
  return request({
    url: '/api/ele/drill/parameter/optionNameList',
    method: 'get',
    params: query
  })
}

// 列表展示
export function queryList(query) {
  return request({
    url: '/api/ele/drill/parameter/list',
    method: 'get',
    params: query
  })
}

// 调参查看
export function getDetailById(id) {
  return request({
    url: '/api/ele/drill/parameter/query/' + id,
    method: 'get',
  })
}

// 数据集下拉框
export function optionIdAndNameList(query) {
  return request({
    url: '/api/ele/drill/dataset/optionIdAndNameList',
    method: 'get',
    params: query
  })
}

// 模型下拉框
export function optionModelNameList(query) {
  return request({
    url: '/api/ele/intelligenele/manage/optionNameList',
    method: 'get',
    params: query
  })
}

// 调参新增
export function addData(data) {
  return request({
    url: '/api/ele/drill/parameter/add',
    method: 'post',
    data: data
  })
}

// 调参修改
export function updateData(data) {
  return request({
    url: '/api/ele/drill/parameter/update',
    method: 'put',
    data: data
  })
}

// 调参删除
export function deleteById(id) {
  return request({
    url: '/api/ele/drill/parameter/delete/' + id,
    method: 'delete'
  })
}

// 保存算法模型（参考，模型管理里面的新增报存）

// 开始训练
export function startModelDrill(id) {
  return request({
    url: '/api/ele/drill/parameter/startModelDrill/' + id,
    method: 'get',
  })
}

// 查看训练
export function progresslist(query) {
  return request({
    url: '/api/ele/drill/parameter/progresslist',
    method: 'get',
    params: query
  })
}

// 查看日志
export function showProgressLog(id) {
  return request({
    url: '/api/ele/drill/parameter/showProgressLog/' + id,
    method: 'get',
  })
}

// 查看结果
export function showProgressImg(id) {
  return request({
    url: '/api/ele/drill/parameter/showProgressImg/' + id,
    method: 'get',
  })
}
