<template>
    <a-modal :visible="showModal" :width="900" :destroyOnClose="true" @cancel="handleCancel">
        <template v-slot:title>
            <span style="font-size:13px">查看结果</span>
        </template>
        <template v-slot:closeIcon>
            <a-icon type="close" style="color: #3C4A54;font-size: 12px;margin-top:10px;margin-right:10px;float:right;" />
        </template>

        <template v-slot:footer>
            <div>
                <a-button @click="handleCancel">关闭</a-button>
            </div>
        </template>
        <div class="main">
            <a-spin :spinning="loading">
                <div class="upload_warp" style="border: 1px solid white;">
                    <div class="upload_warp_img" v-show="list.length!=0">
                        <div class="upload_warp_img_div" v-for="(item,index) of list" :key="index">
                            <div class="upload_warp_img_div_top" >
                                <div class="upload_warp_img_div_text" :title="item.imageName">
                                    {{item.imageName}}
                                </div>
                            </div>
                            <preview-img :src="item.src"/>
                            <!-- <img :src="item.src"> -->
                        </div>
                    </div>
                    <div v-show="list.length===0">暂无数据</div>
                </div>
            </a-spin>
        </div>
    </a-modal>
</template>

<script>
    import { showProgressImg } from "@/api/ai/train.js";
    import {  message, Modal } from 'ant-design-vue';
    import { baseUrl } from '../../utils/request';
    import PreviewImg from '../components/PreviewImg';

    export default {
        name: "ViewTrain",
        components: { PreviewImg },
        props: {
            showModal: {
                type: Boolean,
                default: false
            },
            reportData: {
                type:Object,
                default:null
            },
        },

        data() {
            return {
                loading: false,
                list: [],
            }
        },
        created() {
            this.querylist();
        },
        watch: {
            showModal(value) {
                if (this.showModal) {
                    this.querylist();
                }
            }
        },
        methods:{
            // 获取详情数据
            querylist(){
                if(!this.reportData.id) {
                    return;
                }
                this.loading = true;
                showProgressImg(this.reportData.id).then(res => {
                    this.loading = false;
                    if(res.success && res.data) {
                        this.list = (res.data || []).map(item => {
                            return {
                                ...item,
                                src: 'data:image/jpeg;base64,' + item.imageCode
                            };
                        });
                    }
                });
            },
            handleCancel(){
                this.$emit("close");
            },
        }
    }
</script>

<style scoped>
    .dialogTitle{
        background: lightgray;
        font-size:12px;
    }
    .main {
        background: white;
    }

    .subtitle {
        font-weight: bolder;
        font-size: 24px;
        text-align: center;
        color:black;
    }

    .head {
        height: 61px;
        background: url("../../assets/banner.png") no-repeat;
        background-size:224px 61px
    }

    /*/deep/ .ant-modal-content{*/
    /*  padding: 0px;*/
    /*  margin:0px;*/
    /*  border:1px solid blue;*/
    /*}*/
    /deep/ .ant-modal-header {
  /* background: lightgray; */
  height: 31px;
  padding-top: 3px;
  padding-left: 6px;
  border-bottom: 1px solid #EEEEEE;
}
    /deep/ .ant-modal-body {
        padding: 0px;
    }
    /deep/ .ant-row{
        margin:0px;
        padding:0px;
    }

    .upload_warp_img_div_del {
        position: absolute;
        top: 6px;
        width: 16px;
        right: 4px;
    }
    .upload_warp_img_div_top {
        position: absolute;
        top: 0;
        width: 100%;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.4);
        line-height: 30px;
        text-align: left;
        color: #fff;
        font-size: 12px;
        text-indent: 4px;
    }
    .upload_warp_img_div_text {
        white-space: nowrap;
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .upload_warp_img_div img {
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
    }

    .upload_warp_img_div {
        position: relative;
        height: 120px;
        width: 140px;
        border: 1px solid #ccc;
        margin: 0px 5px 5px 0px;
        float: left;
        line-height: 100px;
        display: table-cell;
        text-align: center;
        background-color: #eee;
        cursor: pointer;
    }

    .upload_warp_img {
        border-top: 1px solid #D2D2D2;
        padding: 15px 0 0 5px;
        overflow: hidden
    }

    .upload_warp_text {
        text-align: left;
        margin-bottom: 5px;
        padding-top: 5px;
        text-indent: 14px;
        border-top: 1px solid #ccc;
        font-size: 14px;
    }

    .upload_warp_right {
        float: left;
        width: 57%;
        margin-left: 2%;
        height: 100%;
        border: 1px dashed #999;
        border-radius: 4px;
        line-height: 130px;
        color: #999;
    }
    .upload_warp_left button {
        margin: 1px 5px 0px 0px;
        cursor:pointer;
    }
    .upload_warp_left {
        float: left;
    }
    .upload_warp_left .btn-success{
        background: rgb(45, 140, 240);
        color:#fff;
    }
    .upload_warp {
        margin: 5px;
    }
    .upload {
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        background-color: #fff;
        width: 100%;
        box-shadow: 0px 1px 0px #ccc;
        border-radius: 4px;
    }
</style>