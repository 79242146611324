<template >
    <div style="width: 100%;height: 100%">
        <img :src="src" @click="modalOpen" style="width: 100%;height: 100%"/>
        <a-modal :visible="modalVisile" :width="width" :destroyOnClose="true" @cancel="handleCancel" :footer="null" class="preview-modal">
            <TransformImage :imgUrl="src"/>
            <!--<img style="width: 100%" :src="src"/>-->
        </a-modal>
    </div>
</template>

<script>
    import TransformImage from './TransformImage';
    export default {
        name: "PreviewImg",
        components: {
            TransformImage,
        },
        props: {
            src: {
                type: String,
                default: '',
            },
            width: 
            {
                type: String,
                default: '70%',
            },
        },
        data(){
            return{
                modalVisile: false,
            }
        },
        created() {},
        watch:{},
        methods: {
            handleCancel() {
                this.modalVisile = false;
            },
            modalOpen() {
               this.modalVisile = true; 
            }
        }
    }

</script>
<style>
    .preview-modal .ant-modal-body {
        padding: 0;
        height: 80vh;
    }
</style>

