<template>
    <a-modal :visible="showModal" :width="580" :destroyOnClose="true" @cancel="handleCancel">
        <template v-slot:title>
            <span style="font-size:13px">保存算法模型</span>
        </template>
        <template v-slot:closeIcon>
            <a-icon type="close" style="color: #3C4A54;font-size: 12px;margin-top:14px;margin-right:14px;float:right;" />
        </template>

        <template v-slot:footer>
            <div>
                <a-button type="primary" style="" @click="saveReport" v-if="!isView">保存</a-button>
                <a-button @click="handleCancel">关闭</a-button>
            </div>
        </template>
        <div class="main">
            <a-spin :spinning="loading">
           <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
                <a-form-model-item label="模型名称" prop="modelName">
                    <a-select v-model="form.modelName" style="width:100%;" :disabled="true" placeholder="请选择模型名称" allowClear show-search>
                        <a-select-option v-for="item in modelList" :key="item.name" :value="item.name">{{ item.name }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="版本信息" prop="modelVersion">
                    <a-input v-model="form.modelVersion" placeholder="请输入模型版本信息" />
                </a-form-model-item>
                <a-form-model-item label="说明" prop="remark">
                    <a-input v-model="form.remark" type="textarea" placeholder="请输入内容" style="height: 125px;"/>
                </a-form-model-item>
            </a-form-model>

        </a-spin>
        </div>
    </a-modal>
</template>

<script>
    import { getDetailById } from "@/api/ai/train.js";
    import { addTrainManage } from "@/api/intelligenele/manage";
    import { optionNameList } from "@/api/image/recognition";
    import {  message, Modal } from 'ant-design-vue';
    import { baseUrl } from '../../utils/request';

    export default {
        name: "SaveModel",
        components: { },
        props: {
            showModal: {
                type: Boolean,
                default: false
            },
            reportData: {
                type:Object,
                default:null
            },
            handelType: {
               type: String,
               default: 'add'
            }
        },

        data() {
            return {
                loading: false,
                form: {},
                modelList: [],
                datasetList: [],
                typeList: [],
                rules: {
                    modelName: [
                        { required: true, message: "模型名称不能为空", trigger: ["change", "blur"] }
                    ],
                    modelVersion: [
                        { required: true, message: "模型版本信息不能为空", trigger: ["change", "blur"]}
                    ],
                },
                isView: false,
            }
        },
        created() {
            this.optionModelNameList();
        },
        watch: {
            showModal(value) {
                if (this.showModal) {
                    this.form = {};
                    this.isView = this.handelType === 'view';
                    this.queryDataById();
                }
            }
        },
        methods:{
            // 获取模型数据
            optionModelNameList() {
                optionNameList().then(res => {
                    if(res.success && res.data) {
                        this.modelList = res.data || [];
                    }
                });
            },
            // 获取详情数据
            queryDataById(){
                if(!this.reportData.id) {
                    return;
                }
                this.loading = true;
                getDetailById(this.reportData.id).then(res => {
                    this.loading = false;
                    if(res.success && res.data) {
                        this.form = res.data || {};
                    }
                });
            },

            saveReport(res){
                this.$refs["form"].validate(async valid => {
                    if (valid) {
                        this.loading = true;
                        const { modelName,modelVersion, remark } = this.form;
                        const res = await addTrainManage({
                            remark, 
                            modelVersion,
                            name: modelName, 
                            drillProgressId: this.reportData.drillProgressId 
                        });
                        this.loading = false;
                        if(res.success) {
                            message.success("操作成功");
                            this.handleCancel();
                            this.$emit("onFresh");
                        }
                    }
                });
            },

            handleCancel(){
                this.$emit("close");
            },
        }
    }
</script>

<style scoped>
    .dialogTitle{
        background: lightgray;
        font-size:12px;
    }
    .main {
        background: white;
        padding: 20px;
    }

    .subtitle {
        font-weight: bolder;
        font-size: 24px;
        text-align: center;
        color:black;
    }

    .head {
        height: 61px;
        background: url("../../assets/banner.png") no-repeat;
        background-size:224px 61px
    }

    /*/deep/ .ant-modal-content{*/
    /*  padding: 0px;*/
    /*  margin:0px;*/
    /*  border:1px solid blue;*/
    /*}*/
    /deep/ .ant-modal-header {
  /* background: lightgray; */
  height: 31px;
  padding-top: 3px;
  padding-left: 6px;
  border-bottom: 1px solid #EEEEEE;
}
    /deep/ .ant-modal-body {
        padding: 0px;
    }
    /deep/ .ant-row{
        margin:0px;
        padding:0px;
    }
    /deep/ .ant-select, .ant-input{
    width: 430px!important;
}
</style>
