import request from '@/utils/request'

// 列表文件名称下拉框数据
export function optionFolderNameList(query) {
    return request({
        url: '/api/ele/image/database/optionFolderNameList',
        method: 'get',
        params: query
    })
}

// 列表样品编号下拉框数据
export function optionSampleNumberList(query) {
  return request({
    url: '/api/ele/image/database/optionSampleNumberList',
    method: 'get',
    params: query
  })
}

// AI模型下拉框数据
export function optionNameList(query) {
    return request({
        url: '/api/ele/intelligenele/manage/optionNameList',
        method: 'get',
        params: query
    })
}

// 原生图片查询
export function queryImage(query) {
    return request({
        url: '/api/ele/image/database/queryOriginImage',
        method: 'get',
        params: query
    })
}

// 原生图片查询--最新历史扫描记录
export function queryImageHistory(query) {
    return request({
        url: '/api/ele/image/database/queryOriginImage/latestRecord',
        method: 'get',
        params: query
    })
}

// 识别
export function imageRecognition(query) {
    return request({
        url: '/api/ele/image/database/imageRecognition',
        method: 'get',
        params: query
    })
}

// 获取模型版本
export function optionModelVersionList(query) {
    return request({
        url: '/api/ele/intelligenele/manage/optionModelVersionList',
        method: 'get',
        params: query
    })
}

