var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.showModal,"width":960,"destroyOnClose":true},on:{"cancel":_vm.handleCancel},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v("查看训练")])]},proxy:true},{key:"closeIcon",fn:function(){return [_c('a-icon',{staticStyle:{"color":"#3C4A54","font-size":"12px","margin-top":"10px","margin-right":"14px","float":"right"},attrs:{"type":"close"}})]},proxy:true},{key:"footer",fn:function(){return [_c('div',[_c('a-button',{on:{"click":_vm.handleCancel}},[_vm._v("关闭")])],1)]},proxy:true}])},[_c('div',{staticClass:"main"},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-table',{ref:"table",attrs:{"rowKey":"id","columns":_vm.columns,"dataSource":_vm.list,"pagination":{
                defaultCurrent: _vm.queryParams.pageNum, // 默认当前页数
                defaultPageSize: _vm.queryParams.pageSize, // 默认当前页显示数据的大小
                total: _vm.total, // 总数，必须先有
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['20', '50', '100'],
                showTotal: (total, range) => `每页${_vm.queryParams.pageSize}条，共 ${total} 条`, // 显示总数
                onShowSizeChange: _vm.onPageSizeChange,
                // 改变每页数量时更新显示
                onChange: _vm.onPageSizeChange,
            },"bordered":""},scopedSlots:_vm._u([{key:"status",fn:function(text){return [(text === '1')?_c('span',[_vm._v("等待中")]):_vm._e(),(text === '2')?_c('span',[_vm._v("训练中")]):_vm._e(),(text === '3')?_c('span',[_vm._v("训练完成")]):_vm._e(),(text === '4')?_c('span',[_vm._v("训练失败")]):_vm._e(),(text === '5')?_c('span',[_vm._v("队列中")]):_vm._e()]}},{key:"action",fn:function(record){return [_c('a',{staticStyle:{"margin-right":"10px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.showLog(record)}}},[_vm._v("查看日志")]),(record.status === '3')?_c('a',{staticStyle:{"margin-right":"10px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.showResult(record)}}},[_vm._v("查看结果")]):_vm._e(),_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.openModelDialog(record)}}},[_vm._v("保存算法模型")])]}}])})],1),_c('save-model',{attrs:{"show-modal":_vm.modelOpen,"report-data":_vm.detailData},on:{"onFresh":_vm.querylist,"close":_vm.closeModelDialog}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }